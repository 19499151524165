export const UserRole = {
  Admin: 0,
  Editor: 1,
};

export const Status = {
  "1": "ACTIVE",
  "2": "INACTIVE",
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;

export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

// Access--codes----for sidebar show according to user roles...........

export const EMPLOYEECODE = 'EMPLOYEES';
export const ALLCPCODE = 'ALL_CPS';
export const PROJECTCODE = 'PROJECTS';
export const PENDINGKYCCODE = 'PENDING_FOR_KYC';
export const VERIFIEDKYCCODE = 'VERIFIED_KYC';
export const CHANGEMAPPINGCODE = 'CHANGE_MAPPING';
export const ACCESSCONTROL = 'ACCESSCONTROL';
export const KNOWLEDGECENTRE = 'KNOWLEDGECENTRE';
export const DASHBOARD = 'DASHBOARD';
export const CPDUMPDOWNLOAD = 'DASHBOARDCPDUMPDUMP';
export const ALLIEDSEVICES = 'ALLIEDSERVICES';
export const RMBOOKINGREQUEST = 'RMBOOKINGREQUEST';
export const CITYHEADBOOKING = 'CITYHEADBOOKING';
export const PAYOUTPLANCODE = 'PAYOUTPLANCODE';
export const VERIFIEDBOOKINGACRECON = 'VERIFIEDBOOKINGACRECON';
export const VERIFIEDBOOKINGFINANCETEAM = 'VERIFIEDBOOKINGFINANCETEAM';

export const ALLINVOICESACRECONTEAMCODE = 'ALLINVOICESACRECONTEAMCODE';
export const ALLINVOICESFINANCETEAMCODE = 'ALLINVOICESFINANCETEAMCODE';
export const permissionData = {
  Project_Create: 'PL__C',
  Project_View: 'PL__R',
  Project_Update: 'PL__U',
  All_CPs_View: 'CP_ALL__R',
  All_CPs_Update_Details: 'CP_ALL__U',

  Pending_For_KYC_View: 'CP_KYC_PEN__R',
  Pending_For_KYC_Update_Verify: 'CP_KYC_PEN__VRFY',
  Verified_KYC_View: 'CP_KYC_VER__V',
  Customer_View_Details: 'CRM__V_DET',
  Customer_Dump: 'CRM__DUMP',
  Customer_Schedule_Event: 'CRM__EVE_SCH',
  Customer_View_Events: 'CRM__EVE_V',
  Customer_Reschedule_Events: 'CRM__EVE_RESCH',
  Customer_Cancel_Events: 'CRM__EVE_CAN',
  Project_Payout_Plan_View: 'COMM__PPP__V',
  Project_Payout_Plan_Create: 'COMM__PPP__C',
  Project_Payout_Plan_Update: 'COMM__PPP__U',
  Project_Payout_Plan_Checker_Action: 'COMM__PPP__CH',
  Project_Payout_Plan_Upload: 'COMM__PPP__UPL',
  Booking_View: 'COMM__BKG__V',
  Booking_Update: 'COMM__BKG__U',
  Booking_Checker_Action: 'COMM__BKG__CH',
  Booking_Dump: 'COMM__BKG__DUMP',
  Invoice_View: 'COMM__INV__V',
  Invoice_Update_Payment_Status: 'COMM__INV__U_PYM_STAT',
  Invoice_Checker_Action: 'COMM__INV__CH',
  Invoice_Dump: 'COMM__INV__DUMP',
  RM_View: 'RM__V',
  RM_Update: 'RM__U',
  AM_View: 'AM__V',
  AM_Update: 'AM__U',
  BD_View: 'BD__V',
  BD_Update: 'BD__U',
  AC_Recon_Team_View: 'AC__V',
  AC_Recon_Team_Update: 'AC__U',
  Finance_Team_View: 'FIN__V',
  Finance_Team_Update: 'FIN__U',
  Strategy_Team_View: 'STR__V',
  Strategy_Team_Update: 'STR__U',
  Employee_View: 'EMP__V',
  Employee_Create: 'EMP__C',
  Employee_Update: 'EMP__U',
  Role_Create: 'ACL__RL__C',
  Role_Update: 'ACL__RL__U',
  Role_Permissions_Create: 'ACL__RP__C',
  Role_Permissions_Update: 'ACL__RP__C',
  All_Project_Bookmarks: 'RPT__PL',
  AM_Reports: 'RPT__AM',
  RM_Reports: 'RPT__RM',
  CP_Reports: 'RPT__CP',
  DashBoards: 'RPT__CP',
  CP_Reports_Dump: 'RPT__CP__DUMP',
  Change_Mapping_View_RM: 'MAP__VRM__R',
  Change_Mapping_View_CP: 'MAP__VCP__R',
  Change_Mapping_RM_Assign: 'MAP__ASSIGN__U',
  Knowledge_Center_Article_View: 'KC_ATCL__R',
  Knowledge_Center_Article_Add: 'KC_ATCL__C',
  Knowledge_Center_Article_Update: 'KC_ATCL__U',
  Allied_Services_View: 'ASR__SER__R',
  Allied_Services_Add: 'ASR__SER__C',
  Allied_Services_Update: 'ASR__SER__U',
  Allied_Services_Providers_View: 'ASR__PROVDR__R',
  Allied_Services_Providers_Add: 'ASR__PROVDR__C',
  Allied_Services_Providers_Update: 'ASR__PROVDR__U',

  Dashboard: 'DSH__CHAR__R',
  CP_Reports_Dump: 'DSH__CPDUMP__R',
  CP_Reports_Dump_Download: 'DSH__CPDUMP__DUMP',
  CP_Performance: 'DSH__CP_PER__R',
  All_Project_Bookmarks: 'DSH__PBOOK__R',
  AM_Performance: 'DSH__AM_PER__R',
  AM_Performance_Locality: 'DSH__AM_PER_LO__R',
  RM_Performance: 'DSH__RM_PER__R',
  RM_Performance_Locality: 'DSH__RM_PER_LO__R',
  RM_Booking_Request_View: 'COMM__BKG_RM__V',
  RM_Booking_Request_Update: 'COMM__BKG_RM__U',
  CityHead_Booking_Request_View: 'COMM__BKG_CH__V',
  CityHead_Booking_Request_Update: 'COMM__BKG_CH__U',
};

export const userAccessPage = (usercode) => {
  let accessObject = {};
  let rolesCode = JSON.parse(localStorage.getItem('USERDETAILS'));

  if (rolesCode !== null || rolesCode !== undefined) {
    let dataRole = rolesCode?.permissions;
    console.log('dataRole-dataRole', dataRole);

    if (dataRole.length > 0) {
      dataRole.forEach((data) => {
        let splitRole = data.split('__');
        if (usercode === EMPLOYEECODE) {
          if (data === 'EMP__C') {
            accessObject['Employee_Create'] = true;
          } else if (data === 'EMP__U') {
            accessObject['Employee_Update'] = true;
          }
        } else if (usercode === PROJECTCODE) {
          if (data === 'PL__C') {
            accessObject['Project_Create'] = true;
          } else if (data === 'PL__R') {
            accessObject['Project_View'] = true;
          } else if (data === 'PL__U') {
            accessObject['Project_Update'] = true;
          }
        } else if (usercode === PENDINGKYCCODE) {
          if (data === 'CP_KYC_PEN__R') {
            accessObject['PendingKycView'] = true;
          } else if (data === 'CP_KYC_PEN__VRFY') {
            accessObject['PendingKycUpdate'] = true;
          }
        } else if (usercode === VERIFIEDKYCCODE) {
          if (data === 'CP_KYC_VER__V') {
            accessObject['VerifiedKycView'] = true;
          }
        } else if (usercode === ALLCPCODE) {
          if (data === 'CP_ALL__R') {
            accessObject['AllCpView'] = true;
          } else if (data === 'CP_ALL__U') {
            accessObject['AllCpUpdate'] = true;
          }
        } else if (usercode === ACCESSCONTROL) {
          if (data === 'ACL__RL__C') {
            accessObject['roleCreate'] = true;
          } else if (data === 'ACL__RL__U') {
            accessObject['roleUpdate'] = true;
          } else if (data === 'ACL__RP__C') {
            accessObject['rolePermissionCreate'] = true;
          } else if (data === 'ACL__RP__U') {
            accessObject['rolePermissionUpdate'] = true;
          }
        } else if (usercode === KNOWLEDGECENTRE) {
          if (data === 'KC_ATCL__R') {
            accessObject['knowledgeCentreArticleRead'] = true;
          } else if (data === 'KC_ATCL__C') {
            accessObject['knowledgeCentreArticleCreate'] = true;
          } else if (data === 'KC_ATCL__U') {
            accessObject['knowledgeCentreArticleUpdate'] = true;
          }
        } else if (usercode === CHANGEMAPPINGCODE) {
          if (data === 'MAP__VRM__R') {
            accessObject['ChangeMappingViewRM'] = true;
          } else if (data === 'MAP__VCP__R') {
            accessObject['ChangeMappingViewCP'] = true;
          } else if (data === 'MAP__ASSIGN__U') {
            accessObject['ChangeMappingRMAssign'] = true;
          }
        } else if (usercode === DASHBOARD) {
          if (data === 'DSH__CPDUMP__R') {
            accessObject['cpDump'] = true;
          } else if (data === 'DSH__CP_PER__R') {
            accessObject['cpPerformance'] = true;
          } else if (data === 'DSH__AM_PER__R') {
            accessObject['amPerformance'] = true;
          } else if (data === 'DSH__AM_PER_LO__R') {
            accessObject['amPerformanceLocality'] = true;
          } else if (data === 'DSH__RM_PER__R') {
            accessObject['rmPerformance'] = true;
          } else if (data === 'DSH__RM_PER_LO__R') {
            accessObject['rmPerformanceLocality'] = true;
          } else if (data === 'DSH__CHAR__R') {
            accessObject['newSignup'] = true;
          }
        } else if (usercode === CPDUMPDOWNLOAD) {
          if (data === 'DSH__CPDUMP__DUMP') {
            accessObject['cpDumpDownload'] = true;
          }
        } else if (usercode === ALLIEDSEVICES) {
          if (data === 'ASR__SER__R') {
            accessObject['alliedServicesRead'] = true;
          } else if (data === 'ASR__SER__C') {
            accessObject['alliedServicesCreate'] = true;
          } else if (data === 'ASR__SER__U') {
            accessObject['alliedServicesUpdate'] = true;
          } else if (data === 'ASR__PROVDR__R') {
            accessObject['alliedServicesProviderRead'] = true;
          } else if (data === 'ASR__PROVDR__C') {
            accessObject['alliedServicesProviderCreate'] = true;
          } else if (data === 'ASR__PROVDR__U') {
            accessObject['alliedServicesProviderUpdate'] = true;
          }
        } else if (usercode === RMBOOKINGREQUEST) {
          if (data === 'COMM__BKG_RM__V') {
            accessObject['rmBookingRequestView'] = true;
          } else if (data === 'COMM__BKG_RM__U') {
            accessObject['rmBookingRequestUpdate'] = true;
          }
        } else if (usercode === CITYHEADBOOKING) {
          if (data === 'COMM__BKG_CH__V') {
            accessObject['cityHeadBookingRequestView'] = true;
          } else if (data === 'COMM__BKG_CH__U') {
            accessObject['cityHeadBookingRequestUpdate'] = true;
          }
        } else if (usercode === PAYOUTPLANCODE) {
          if (data === 'COMM__PPP__V') {
            accessObject['payoutPlanView'] = true;
          } else if (data === 'COMM__PPP__C') {
            accessObject['payoutPlanCreate'] = true;
          } else if (data === 'COMM__PPP_FIN__U') {
            accessObject['payoutPlanFinanceUpdate'] = true;
          } else if (data === 'COMM__PPP__CHA') {
            accessObject['payoutPlanFinanceApproveReject'] = true;
          }
        } else if (usercode === VERIFIEDBOOKINGACRECON) {
          if (data === 'COMM__BKG_MIL_PEN_AC__V') {
            accessObject['bookingMilestoneViewByAC'] = true;
          } else if (data === 'COMM__BKG_MIL_PEN_AC__U') {
            accessObject['bookingMilestoneUpdateByAC'] = true;
          }
        } else if (usercode === VERIFIEDBOOKINGFINANCETEAM) {
          if (data === 'COMM__BKG_MIL_PEN_FIN__V') {
            accessObject['bookingMilestoneViewByFinance'] = true;
          } else if (data === 'COMM__BKG_MIL_PEN_FIN__U') {
            accessObject['bookingMilestoneUpdateByFinance'] = true;
          }
        } else if (usercode === ALLINVOICESACRECONTEAMCODE) {
          if (data === 'COMM__INV_AC__V') {
            accessObject['allInvoiceViewACRecon'] = true;
          } else if (data === 'COMM__INV_AC__U') {
            accessObject['allInvoiceUpdateACRecon'] = true;
          }
          if (data === 'COMM__INV_AC_PAY__V') {
            accessObject['allInvoicePaymentViewACRecon'] = true;
          } else if (data === 'COMM__INV_AC_PAY__U') {
            accessObject['allInvoicePaymentUpdateACRecon'] = true;
          }
        } else if (usercode === ALLINVOICESFINANCETEAMCODE) {
          if (data === 'COMM__INV_FIN__V') {
            accessObject['allInvoiceViewFinance'] = true;
          } else if (data === 'COMM__INV_FIN__U') {
            accessObject['allInvoiceUpdateFinance'] = true;
          }
          if (data === 'COMM__INV_FIN_PAY__V') {
            accessObject['allInvoicePaymentViewFinance'] = true;
          } else if (data === 'COMM__INV_FIN_PAY__U') {
            accessObject['allInvoicePaymentUpdateFinance'] = true;
          }
        }
        // COMM__INV_AC__U
      });
    }
  }
  console.log('accessObject-default', accessObject);
  return accessObject;
};
