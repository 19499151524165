import { getAxiosRequest } from './axiosFunctions';

let moment = require('moment');
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  defaultColor,
  defaultDirection,
  defaultLocale,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {}
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {}
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {}
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {}
};

export const getCurrentUser = () => {
  let user = {};
  try {
    user =
      localStorage.getItem('USERTOKEN') != null
        ? localStorage.getItem('USERTOKEN')
        : null;
  } catch (error) {
    user = null;
  }
  return user;
};
export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {}
};

export const getVerifiedByUser = (userId) => {
  let url = `${process.env.REACT_APP_AUTH_API_URL}/user/${userId}/`;

  return getAxiosRequest(url)
    .then((res) => {
      let user = res?.data?.response;

      if (user) {
        return res?.data?.response?.name;
      }

      return '----';
    })
    .catch((err) => {
      return '----';
    });
};

export const formatDate = (date) => {
  if (date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }
  return '-----';
};

export const checkOnlyWhiteSpaceInput = (str) => {
  if (str.match(/^\s*$/)) {
    return true;
  } else {
    return false;
  }
};
export const dateFormatConversion = (time) => {
  let yearFormat = moment.utc(time).utcOffset('+05:30').format('DD MMM YYYY');
  let timeFormat = moment.utc(time).utcOffset('+05:30').format('hh:mm A');
  let yearTime = `${yearFormat} ${timeFormat}`;
  return yearTime;
};

// toased message

export const showSuccessMessage = (message) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export const showFailedMessage = (message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export const showWarningMessage = (message) => {
  toast.warning(message, {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export const isValidPinCode = (pinCode) => {
  if (pinCode.length == 0) {
    return false;
  }
  if (/^[1-9]{1}[0-9]{2}[0-9]{3}$/.test(pinCode)) {
    return true;
  } else {
    return false;
  }
};
//
export const nameValidation = (name) => {
  if (name.length == 0) {
    return false;
  }
  if (/^[a-zA-Z ]{2,30}$/.test(name)) {
    return true;
  } else {
    return false;
  }
};

export const ValidateMobileNumber = (numberPhone) => {
  var expr = /^(0|91)?[6-9][0-9]{9}$/;

  if (!expr.test(numberPhone)) {
    return false;
  }
  return true;
};

export const ValidateEmail = (emailVal) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailVal)) {
    return true;
  }

  return false;
};

export const gstValidation = (gstVal) => {
  if (!gstVal) {
    return false;
  }
  if (
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gstVal)
  ) {
    return true;
  }
  return false;
};

export const panNumberValidation = (panVal) => {
  if (!panVal) {
    return false;
  }
  if (/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(panVal)) {
    return true;
  }
  return false;
};
