import axios from 'axios';

export const getAxiosRequest = async (url) => {
  console.log(localStorage.getItem('USERTOKEN'), 'tokenlocal');
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('USERTOKEN')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postAxiosRequest = async (url, data = {}, params) => {
  return axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('USERTOKEN')}`,
      },
      params
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const patchAxiosRequest = async (url, data = {}) => {
  return axios
    .patch(url, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('USERTOKEN')}`,
      },
    })
    .then((res) => res)
    .catch((err) => err);
};

export const getLocationResponse = async (locality) => {
  const locationApi = `https://maps.googleapis.com/maps/api/geocode/json?address=${locality}&key=${process.env.REACT_APP_LOCALITY_API_KEY}`;
  return axios
    .get(locationApi)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
